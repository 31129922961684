<template>
  <v-container>
    <v-card class="pa-0">
      <v-card-title>
        Resource List
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="pa-0"
        ></v-text-field>
        <v-spacer />
        <v-btn
          color="primary"
          @click="show_add_resource_modal = true"
          depressed
        >
          <v-icon class="mr-1">mdi-plus</v-icon>
          Add Resource
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        dense
        :headers="headers"
        :search="search"
        :items="items.results"
        :server-items-length="items.total"
        :options.sync="pagination"
        :loading="loading"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:body="">
          <draggable
            :list="items.results"
            v-bind="dragOptions"
            tag="tbody"
            @change="changeOrder"
          >
            <tr
              v-for="(resource, index) in items.results"
              :key="index"
              style="cursor: move !important"
            >
              <td>
                {{ resource.title }}
              </td>
              <td>
                {{ resource.description }}
              </td>
              <td>
                <v-btn
                  v-if="resource.file_type == 'DOCUMENT'"
                  color="grey darken-2 px-2"
                  small
                  dark
                  @click="viewResource(resource.file_info.location)"
                >
                  <v-icon class="pr-2">mdi-file-document</v-icon> View
                </v-btn>
                <v-btn
                  v-if="resource.file_type == 'LINK'"
                  color="grey darken-2 px-2"
                  small
                  dark
                  @click="viewResource(resource.file_link)"
                >
                  <v-icon class="pr-2">mdi-link</v-icon> View
                </v-btn>
                <v-btn
                  v-if="resource.file_type == 'IMAGE'"
                  color="grey darken-2 px-2"
                  small
                  dark
                  @click="showPreview(resource)"
                >
                  <v-icon class="pr-2">mdi-image-area</v-icon> View
                </v-btn>
                <v-btn
                  v-if="resource.file_type == 'VIDEO'"
                  color="grey darken-2 px-2"
                  small
                  dark
                  @click="showPreview(resource)"
                >
                  <v-icon class="pr-2">mdi-play</v-icon> Play
                </v-btn>
              </td>
              <td>
                <v-btn
                  color="grey darken-2"
                  icon
                  dark
                  @click="openEditDialog(resource)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  color="grey darken-2"
                  icon
                  dark
                  @click="confirmDelete(resource)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </v-card>
    <!-- Delete confirmation -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-text class="pt-5"> Are you sure delete? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-4" text @click="deleteItem(resource_data)"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Video play -->
    <v-dialog v-model="preview_data" max-width="600" v-if="show_preview">
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0">
          <iframe
            :src="preview_data.file_info.location"
            style="width: 100%; height: 100%"
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_preview = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add Resource Modal -->
    <v-dialog v-model="show_add_resource_modal" max-width="500px" persistent>
      <v-card>
        <v-card-title class="headline">Add Resource</v-card-title>
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="form_data.title"
                :error-messages="form_error.title"
                dense
                label="Title"
                outlined
                :hide-details="form_error.title == null"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                rows="3"
                v-model="form_data.description"
                :error-messages="form_error.description"
                dense
                label="Description"
                outlined
                :hide-details="form_error.description == null"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11">
              <v-file-input
                v-model="form_data.file"
                :error-messages="form_error.file"
                accept="image/*, video/*,document/*,.pdf"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
                v-if="custom_file_mode"
                persistent-hint
              ></v-file-input>
              <v-text-field
                v-model="form_data.link"
                :error-messages="form_error.link"
                dense
                label="Link"
                prepend-icon="mdi-link"
                outlined
                placeholder="https://"
                v-else
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="custom_file_mode = !custom_file_mode"
                    v-bind="attrs"
                    v-on="on"
                    left
                    class="mt-3"
                  >
                    <v-icon v-if="!custom_file_mode"
                      >mdi-file-upload-outline</v-icon
                    >
                    <v-icon v-else>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span
                  >Switch to
                  {{ !custom_file_mode ? "File upload" : "Link" }}</span
                >
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            @click="addObject"
            :loading="loading"
            :disabled="loading"
            >save</v-btn
          >
          <v-btn
            color="primary"
            text
            @click="
              show_add_resource_modal = false;
              form_error = {};
            "
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" max-width="500px" persistent>
      <v-card v-if="selectedItem">
        <v-card-title class="headline">Edit Resource</v-card-title>

        <v-card-text class="pt-5 pb-3">
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="selectedItem.title"
                :error-messages="form_error.title"
                dense
                label="Title"
                outlined
                autofocus
                :hide-details="form_error.title == null"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                rows="3"
                v-model="selectedItem.description"
                :error-messages="form_error.description"
                dense
                label="Description"
                outlined
                :hide-details="form_error.description == null"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="mt-2 pb-0">
            <v-col cols="9">
              <v-file-input
                v-model="editFile"
                :error-messages="form_error.file"
                accept="image/*, video/*,document/*,.pdf"
                placeholder="Select File"
                prepend-icon="mdi-file"
                outlined
                dense
                v-if="edit_custom_file_mode"
                persistent-hint
              ></v-file-input>
              <v-text-field
                v-model="editFileLink"
                :error-messages="form_error.link"
                dense
                label="Link"
                prepend-icon="mdi-link"
                outlined
                placeholder="https://"
                v-else
                persistent-hint
              ></v-text-field>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="edit_custom_file_mode = !edit_custom_file_mode"
                    v-bind="attrs"
                    v-on="on"
                    left
                    class="mt-3"
                  >
                    <v-icon v-if="!edit_custom_file_mode"
                      >mdi-file-upload-outline</v-icon
                    >
                    <v-icon v-else>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span
                  >Switch to
                  {{ !edit_custom_file_mode ? "File upload" : "Link" }}</span
                >
              </v-tooltip>
            </v-col>
            <v-col cols="1">
              <v-btn
                v-if="selectedItem.file_type == 'DOCUMENT'"
                color="primary px-0"
                dark
                @click="viewResource(selectedItem.file_info.location)"
              >
                View
              </v-btn>
              <v-btn
                v-if="selectedItem.file_type == 'LINK'"
                color="primary px-0"
                dark
                @click="viewResource(selectedItem.file_link)"
              >
                View
              </v-btn>
              <v-btn
                v-if="selectedItem.file_type == 'IMAGE'"
                color="primary px-0"
                dark
                @click="showPreview(selectedItem)"
              >
                View
              </v-btn>
              <v-btn
                v-if="selectedItem.file_type == 'VIDEO'"
                color="primary px-0"
                dark
                @click="showPreview(selectedItem)"
              >
                View
              </v-btn>
            </v-col>
          </v-row>
          <!-- <v-row class="pt-0 mt-0">
            <v-col cols="12">
              
            </v-col>
          </v-row> -->
        </v-card-text>

        <v-card-actions class="pb-5 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="green" dark @click="updateItem" :loading="loading"> Update </v-btn>
          <v-btn color="primary" text @click="closeEditDialog"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import draggable from "vuedraggable";
import { parseParams, getAlphabets } from "@/plugins/helper";
import { mapMutations } from "vuex";
export default {
  name: "ResourceList",
  components: {
    draggable,
  },
  data() {
    return {
      show_add_resource_modal: false,
      form_data: {
        vendor_id: this.vendor_id,
        resource_type_id: "613b3b380bbce02aec454c1e",
      },
      form_error: {},
      vendor_id: null,
      preview_data: null,
      show_preview: false,
      loading: false,
      dialog: false,
      resource_data: {},
      search: "",
      items: {
        current_page: 1,
        per_page: 10,
        data: [],
      },
      headers: [
        { text: "Title", value: "title", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Link/File", value: "file", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      pagination: {
        itemsPerPage: 10,
        sortBy: ["order"],
        sortDesc: false,
        page: 1,
        filters: {
          item_active: true,
          alphabet: "",
        },
      },
      alphabets: getAlphabets(),
      custom_file_mode: true,
      sizename: ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      usage_satistics: {
        remaining: 0,
      },
      show_edit_resource_modal: false,
      edit_form_data: {},
      edit_form_error: {},
      editDialog: false,
      edit_custom_file_mode: true,
      selectedItem: null,
      editFile: null,
      editFileLink: null,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "company",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    pageData() {
      let paginationData = this.pagination;
      paginationData.search = this.search;
      paginationData.searchOption = "title";
      return paginationData;
    },
    getStyles() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return {
          maxWidth: "95%",
          margin: "0 auto",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    search() {
      this.getItems();
    },
    pagination: {
      handler: function () {
        this.getItems();
      },
      deep: true,
    },
    show_add_resource_modal: {
      handler: function (val) {
        if (!val) {
          this.form_data = {
            vendor_id: this.vendor_id,
            resource_type_id: "613b3b380bbce02aec454c1e",
          };
        }
      },
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    showPreview(item) {
      this.preview_data = item;
      this.show_preview = true;
    },
    getItems() {
      let _self = this;
      this.loading = true;
      let url = parseParams(this.pageData);
      this.$axios
        .get(`/vendor/resource/list/${this.vendor_id}?${url}`)
        .then((response) => {
          _self.items = response.data.data;
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    viewResource(href) {
      if (href) {
        Object.assign(document.createElement("a"), {
          target: "_blank",
          href,
        }).click();
      }
    },
    confirmDelete(item) {
      this.dialog = true;
      this.resource_data = item;
    },
    updateItem() {
      // validate the file
      if (this.edit_custom_file_mode) {
        if (this.editFile) {
          const fileSizeLimit = 5 * 1024 * 1024; // 5 MB

          if (this.editFile.size > fileSizeLimit) {
            this.form_error = {
              ...this.form_error,
              file: "File size limit excceded(Please upload file of size less then 5 mb)"
            };
            return;
          }
        }
      }

      this.loading = true;

      let formData = new FormData();

      formData.append("title", this.selectedItem.title || "");
      formData.append("description", this.selectedItem.description || "");
      if (this.editFile && this.edit_custom_file_mode) {
        formData.append("file", this.editFile);
      }
      formData.append("is_file", this.edit_custom_file_mode);
      formData.append("link", this.editFileLink || "");

      this.$axios
        .put(`/vendor/resource/edit/${this.selectedItem.id}`, formData)
        .then((res) => {
          let data = res.data;
          if (data.status) {
            this.form_error = {};
            this.getItems();
            this.closeEditDialog();
            this.setAlert({
              show: true,
              variant: "success",
              message: data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = data.error;
            for (let key in errors) {
              this.form_error[errors[key].field] = errors[key].message;
            }
          }
          this.loading = false;
        })
        .catch(function () {
          this.loading = false;
        });
    },
    deleteItem(item) {
      let _self = this;
      _self.loading = true;
      this.$axios
        .delete(`/vendor/resource/delete/${item.id}`)
        .then((res) => {
          if (res.status) {
            _self.getItems();
            _self.dialog = false;
            _self.setAlert({
              show: true,
              variant: "success",
              message: res.data.message,
              dismissCountDown: 5000,
            });
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    addObject() {
      let _self = this;
      // validate the file
      if (this.custom_file_mode) {
        if (!this.form_data.file) {
          this.form_error = {
            ...this.form_error,
            file: "File is required"
          };
          return;
        }

        const fileSizeLimit = 5 * 1024 * 1024; // 5 MB

        if (this.form_data.file.size > fileSizeLimit) {
          this.form_error = {
            ...this.form_error,
            file: "File size limit excceded(Please upload file of size less then 5 mb)"
          };
          return;
        }
      } else {
        if (!this.form_data.link) {
          this.form_error = {
            ...this.form_error,
            link: "Link is required"
          };
          return;
        }
      }

      this.loading = true;
      let serverData = JSON.parse(JSON.stringify(this._data.form_data));
      let formData = new FormData();
      for (let key in serverData) {
        formData.append(key, serverData[key]);
      }
      if (this.form_data.file && this.custom_file_mode) {
        formData.append("file", this.form_data.file);
      }
      formData.append("is_file", this.custom_file_mode);
      formData.append("link", this.form_data.link || "");
      formData.append("vendor_id", this.vendor_id);
      this.$axios
        .post(`/vendor/resource/add`, formData)
        .then((response) => {
          _self.form_error = {};
          let response_data = response.data;
          if (response_data.status) {
            this.form_error = {};
            _self.setAlert({
              show: true,
              variant: "success",
              message: response.data.message,
              dismissCountDown: 5000,
            });
            _self.show_add_resource_modal = false;
            _self.getItems();
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message;
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    niceBytes(x) {
      let l = 0,
        n = parseInt(x, 10) || 0;
      while (n >= 1024 && ++l) {
        n = n / 1024;
      }
      return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + this.sizename[l];
    },
    changeOrder() {
      let _self = this;
      _self.loading = true;

      let new_order = [];

      for (let i = 0; i < _self.items.results.length; i++) {
        new_order.push({
          index: i,
          id: _self.items.results[i].id,
        });
      }

      this.$axios
        .post("/vendor/resource/change_order", {
          new_order: new_order,
        })
        .then((res) => {
          if (res.status) {
            res;
          }
          _self.loading = false;
        })
        .catch(function () {
          _self.loading = false;
        });
    },
    openEditDialog(resource) {
      this.selectedItem = { ...resource };
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.selectedItem = null;
      this.editFile = null;
      this.editFileLink = null;
      this.edit_custom_file_mode = true;
      this.form_error = {};
    },
  },
  mounted() {
    let localData = JSON.parse(localStorage.getItem("vendor_data"));
    this.vendor_id = localData.id;
  },
};
</script>


<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 1;
  background: #dadada80 !important;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: #fff;
}
</style>