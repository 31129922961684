<template>
  <span>
    <basic-booth v-if="user_data.sponsor_type == '6136fb0dfac75732b88a8258'" />
    <gold-booth v-if="user_data.sponsor_type == '6136fd8f04028dbb169b1694'" />
    <diamond-booth v-if="user_data.sponsor_type == '6136fda104028dbb169b1695'" />
  </span>
</template>
<script>
import BasicBooth from "@/components/booth/BasicBooth";
import GoldBooth from "@/components/booth/GoldBooth";
import DiamondBooth from "@/components/booth/DiamondBooth";
import { mapMutations, mapState } from "vuex";
export default {
  name: "MainImage",
  components: {
    BasicBooth,
    GoldBooth,
    DiamondBooth
  },
  data(){
    return {
      user_data : {
        sponsor_type : ''
      }
    }
  },
  computed : {
    ...mapState("utils", ["isBoothPreview"]),
  },
  methods : {
    ...mapMutations("utils", ["setUtilData"]),
  },
  mounted(){
    if(this.$route.params.type=='view'){
      this.setUtilData({
        key : 'isBoothPreview',
        data : true
      })
    }
    this.user_data = JSON.parse(localStorage.getItem("vendor_data"));
  }
}
</script>
<style>
.cursor_edit {
  cursor: url('/edit-20.png'), auto;
}
</style>