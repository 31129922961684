<template>
  <div style="height: 100%">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1440"
      id="mainImage"
    >
      <defs>
        <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="contact_details_bg">
          <feFlood flood-color="rgba(100,100,100,0.2)" result="bg" />
          <feMerge>
            <feMergeNode in="bg"/>
            <feMergeNode in="SourceGraphic"/>
            <feComposite in2="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <defs>
        <clipPath id="clip-Canvas">
          <rect width="1920" height="1440" />
        </clipPath>
      </defs>
      <g id="Canvas" clip-path="url(#clip-Canvas)">
        <rect width="1920" height="1440" fill="rgba(0,0,0,0)" />
        <g id="Color_Selections" data-name="Color Selections">
          <image
            id="booth_gold_red"
            width="1920"
            height="1440"
            v-if="vendor_details.booth_color"
            :xlink:href="asset_url + vendor_details.booth_color + '.png'"
          />
        </g>
        <g id="Avatars">
          <image
            id="avatar_male_black"
            width="160"
            height="208"
            transform="translate(775 668)"
            :xlink:href="asset_url + getAvatarPath(vendor_details.booth_avatar)"
          />
          <!-- avatar_male_black,avatar_female_black,avatar_male_asian,avatar_female_asian,avatar_male_latino,avatar_female_latina,avatar_male_white,avatar_female_white -->
        </g>
        <g id="Mapping">
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="40"
            height="40"
            v-if="details.social_links && details.social_links.facebook"
            fill="rgba(254,219,190,0)"
            :transform="
              'translate(' + details.social_links.facebook_x + ' 516)'
            "
          >
            <title>Facebook {{ details }}</title>
          </rect>
          <image
            id="Layer_2"
            data-name="Layer 2"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.facebook_x + ' 516)'
            "
            v-if="details.social_links && details.social_links.facebook"
            :xlink:href="asset_url + '../../facebook_icon.png'"
            @click="openLink('/profile')"
          >
            <title>Facebook</title>
          </image>
          <rect
            id="Rectangle_8_copy"
            data-name="Rectangle 8 copy"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.linkedin_x + ' 516)'
            "
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.linkedin"
          >
            <title>Linkedin</title>
          </rect>
          <image
            id="Layer_3"
            data-name="Layer 3"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.linkedin_x + ' 516)'
            "
            :xlink:href="asset_url + '../../linkedin_icon.png'"
            v-if="details.social_links && details.social_links.linkedin"
            @click="openLink('/profile')"
          >
            <title>Linkedin</title>
          </image>
          <rect
            id="Rectangle_8_copy_2"
            data-name="Rectangle 8 copy 2"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.twitter_x + ' 516)'"
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.twitter"
          >
            <title>Twitter</title>
          </rect>
          <image
            id="Layer_4"
            data-name="Layer 4"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.twitter_x + ' 516)'"
            :xlink:href="asset_url + '../../twitter_icon.png'"
            v-if="details.social_links && details.social_links.twitter"
            @click="openLink('/profile')"
          >
            <title>Twitter</title>
          </image>
          <rect
            id="Rectangle_8_copy_3"
            data-name="Rectangle 8 copy 3"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.instagram_x + ' 516)'
            "
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.instagram"
          >
            <title>Instagram</title>
          </rect>
          <image
            id="Layer_5"
            data-name="Layer 5"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.instagram_x + ' 516)'
            "
            :xlink:href="asset_url + '../../instagram_icon.png'"
            v-if="details.social_links && details.social_links.instagram"
            @click="openLink('/profile')"
          >
            <title>Instagram</title>
          </image>
          <rect
            id="Rectangle_8_copy_4"
            data-name="Rectangle 8 copy 4"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.youtube_x + ' 516)'"
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.youtube"
          >
            <title>Youtube</title>
          </rect>
          <image
            id="Layer_6"
            data-name="Layer 6"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.youtube_x + ' 516)'"
            :xlink:href="asset_url + '../../youtube_icon.png'"
            v-if="details.social_links && details.social_links.youtube"
            @click="openLink('/profile')"
          >
            <title>Youtube</title>
          </image>
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="300"
            height="120"
            transform="translate(978 925)"
            fill="white"
            stroke="black"
            stroke-width="2px"            
            class="cursor-pointer link-opcaity"
            @click="addObjectView(vendor_details.object_data.G4)"
            v-if="!(vendor_details.object_data && vendor_details.object_data.G4 && vendor_details.object_data.G4.contact_details)"
          >
            
          </rect>
          <text
            id="Layer_7"
            filter="url(#contact_details_bg)"
            style="font-weight: 500"
            data-name="Layer 7"
            width="192"
            height="60"
            transform="translate(978 940)"
            fill="black"
            v-if="vendor_details.object_data && vendor_details.object_data.G4 && vendor_details.object_data.G4.contact_details"
            @click="addObjectView(vendor_details.object_data.G4)"
            v-html="getContactDetails(vendor_details.object_data.G4.contact_details)"
            class="cursor-pointer"
            title="Click here to edit contact details"
          >
          </text>
          <text id="Layer_7"
            data-name="Layer 7"
            width="192"
            height="60"
            transform="translate(1040, 980)"
            class="cursor-pointer"
            font-size="22px"
            fill="black" v-else
          >
            Edit contact details
          </text>

          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="200"
            height="370"
            transform="translate(1555 833)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>Click here to update the banner</title>
          </rect>
          <image
            id="Layer_8"
            data-name="Layer 8"
            width="200"
            height="370"
            transform="translate(1555 833)"
            v-if="vendor_details && vendor_details.object_data"
            :xlink:href="vendor_details.object_data.G5.upload_url"
            @click="addObjectView(vendor_details.object_data.G5)"
            class="cursor-pointer"
          >
            <title>Click here to update the banner</title>
          </image>
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="180"
            height="180"
            transform="translate(357 676)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>Click To Edit Giveaway Image</title>
          </rect>
          <image
            id="Layer_9"
            data-name="Layer 9"
            width="180"
            height="180"
            transform="translate(357 676)"
            :content="vendor_details.object_data.G3.upload_url"
            v-if="vendor_details && vendor_details.object_data"
            :xlink:href="vendor_details.object_data.G3.upload_url"
            @click="addObjectView(vendor_details.object_data.G3)"
            class="cursor-pointer"
          >
            <title>Click To Edit Giveaway Image</title>
          </image>
          <rect
            id="Rectangle_6"
            ref="video_iframe"
            data-name="Rectangle 6"
            width="426"
            height="240"
            transform="translate(967 646)"
            fill="rgba(254,219,190,0)"
          />
          <!-- <image id="Layer_10" data-name="Layer 10" width="426" height="240" transform="translate(967 646)" v-if="details && details.object_data" :xlink:href="details.object_data.G1.upload_url"/> -->
          <rect
            id="Rectangle_3"
            ref="desc_image"
            class="st0 cursor-pointer"
            data-name="Rectangle 3"
            width="200"
            height="240"
            transform="translate(557 646)"
            fill="rgba(254,219,190,0)"
            @click="openLink('/profile')"
          >
            <title>Click here to edit the description of company</title>
          </rect>
          <image
            id="Layer_11"
            class="link-opacity cursor-pointer"
            data-name="Layer 11"
            width="200"
            height="240"
            transform="translate(557 646)"
            :xlink:href="asset_url + 'VBCEH_Booth_Gold_4x3-image32.png'"
            @click="openLink('/profile')"
          >
            <title>Click here to edit the description of company</title>
          </image>
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            width="80"
            height="80"
            transform="translate(815 961)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>Click to edit the booth icon</title>
          </rect>
          <image
            id="Layer_12"
            data-name="Layer 12"
            width="80"
            height="80"
            transform="translate(815 961)"
            v-if="vendor_details && vendor_details.object_data"
            :xlink:href="vendor_details.object_data.G6.upload_url"
            @click="addObjectView(vendor_details.object_data.G6)"
            class="cursor-pointer"
          >
            <title>Click to edit the booth icon</title>
          </image>
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="160"
            height="36"
            transform="translate(775 839)"
            fill="rgba(254,219,190,0)"
            class=""
          />
          <image
            id="Layer_13"
            class="link-opacity  cursor-pointer"
            data-name="Layer 13"
            width="160"
            height="36"
            transform="translate(775 839)"
            @click="openLink('/resource/list')"
            :xlink:href="asset_url + 'VBCEH_Booth_Gold_4x3-image34.png'"
          />
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="442"
            height="176"
            transform="translate(614 361)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>Click here to change the logo</title>
          </rect>
          <image
            id="Layer_14"
            data-name="Layer 14"
            width="442"
            height="176"
            transform="translate(614 361)"
            v-if="vendor_details && vendor_details.object_data"
            :xlink:href="vendor_details.object_data.G2.upload_url"
            @click="addObjectView(vendor_details.object_data.G2)"
            class="cursor-pointer"
          >
            <title>Click here to change the logo</title>
          </image>
        </g>
      </g>
    </svg>
    <span
      ref="custom_html"
      style="color: white; font-size: 0.9vw;word-break: break-word;"
      class="pl-1 cursor-pointer"
      @click="openLink('/profile')"
      v-if="details.company_desc"
    >
      <span class="ellipsis custom_sub_html v_g_description_container" :class="[vendor_details.booth_color+'_custom_color']" ref="custom_sub_html" title="Click here to edit description" v-html="shortCompanyDescription"></span>
      <span
        v-if="details.company_desc.length > desc_size"
        class="yellow--text cursor_pointer"
        @click="show_desc_modal = true"
        v-show="descripton_overflow"
      >
        Read More
      </span>
    </span>
    <span ref="video_iframe_span" class="pl-1 dynamic-slider" v-if="vendor_details" title="Click on the edit videos button to add videos">
      <div v-if="vendor_details.object_data && vendor_details.object_data.G1.multiple_objects.length == 0" class="cursor-pointer white d-flex align-center" style="height: 100%;  border: 2px solid black !important;" @click="editVideosDialog = true">
        <p class="text-caption text-md-body-1 text-sm-body-2 text-lg-h6 grey--text text--darken-2 text-center" style="width:100%;">Add Video/Image<br>
        290 x 165 pixels</p>
      </div>
      <div v-else style="height: 100%;">
      <v-carousel
        v-if="vendor_details.object_data"
        v-model="model"
        :show-arrows="false"
        hide-delimiters
        ref="vcarousel"
      >
        <v-carousel-item
          v-for="(data, i) in vendor_details.object_data.G1.multiple_objects"
          :key="data.id"
          ref="vcarouselitem"
        >
          <v-row class="fill-height ma-0" v-if="model == i">
            <video :id="i" width="100%" ref="iframe" v-if="vendor_details && vendor_details.object_data && data.type=='VIDEO'" :src="'https://vbcassets.s3.us-east-2.amazonaws.com/uploads/objects/'+data.path" controls autoplay></video>
            <img :id="i" v-else-if="vendor_details && vendor_details.object_data && data.type=='IMAGE'" width="100%" height="100%" :src="'https://vbcassets.s3.us-east-2.amazonaws.com/uploads/objects/'+data.path" :style="{ objectFit: 'contain !important', cursor: data.link ? 'pointer' : 'normal' }" @click="data.link ? openExternalLink(data) : null"/>
            <iframe :id="i" width="100%" ref="iframe" v-else-if="vendor_details && vendor_details.object_data && data.type=='LINK'" :src="data.link" muted sandbox="allow-scripts allow-presentation allow-same-origin" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write" allowfullscreen webkitallowfullscreen mozallowfullscreen frameborder="0"></iframe>
            <iframe :id="i" width="100%" ref="iframe" v-else-if="vendor_details && vendor_details.object_data" :src="'https://vbcassets.s3.us-east-2.amazonaws.com/uploads/objects/'+data.path" muted sandbox="allow-scripts allow-presentation allow-same-origin" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write" autoplay="0" allowfullscreen webkitallowfullscreen mozallowfullscreen frameborder="0"></iframe>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      </div>
      <v-btn
      color="primary"
      style="position:absolute; top: -45px; right: 0px"
      @click="editVideosDialog = true"
    >
      Edit Video Content
    </v-btn>
    </span>
    <span ref="carousel-prev-btn">
      <v-btn
        v-if="vendor_details && vendor_details.object_data && vendor_details.object_data.G1.multiple_objects.length>1"
        x-small
        color="warning"
        dark
        elevation="0"
        :disabled="model == 0"
        @click="model--"
      >
        <v-icon size="18">
            mdi-chevron-left
        </v-icon>
      </v-btn>
    </span>
    <span ref="carousel-next-btn">
      <v-btn
        v-if="vendor_details && vendor_details.object_data && vendor_details.object_data.G1.multiple_objects.length>1"
        x-small
        color="warning"
        dark
        elevation="0"
        :disabled="model == vendor_details.object_data.G1.multiple_objects.length -1"
        @click="model++"
      >
        <v-icon size="18">
            mdi-chevron-right
        </v-icon>
      </v-btn>
    </span>
    <AddObject />
    <v-dialog v-model="show_desc_modal" max-width="50%">
      <v-card>
        <v-card-title class="pa-3 pb-1">Company Description</v-card-title>
        <v-card-text class="pa-3 pb-0 rounded-lg" v-html="getUnescapedString(vendor_details.company_desc)">
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_desc_modal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="editVideosDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5 mb-5">
          Add Video/Image
          <v-spacer />
          <v-btn color="primary" dark rounded @click="addVideoDialog = true; video_errors = {}; selected_videos= [];">
            <v-icon left>
              mdi-plus
            </v-icon>
            Add Files
          </v-btn>
        </v-card-title>
        <v-card-text class="add_video_content_container">
          <v-expansion-panels focusable v-if="videoExists()">
            <v-expansion-panel
              v-for="(video, index) in vendor_details.object_data.G1.multiple_objects"
              :key="video.path"
            >
              <v-expansion-panel-header>
                <v-row>
                  <v-col cols="9" class="mt-3">
                    {{ video.path ? video.path : `Video ${index +1}` }}
                  </v-col>
                  <v-col cols="3">
                    <v-tooltip bottom v-if="video.type === 'IMAGE'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click.stop="showAddLinkDialog(video)"
                        >
                          <v-icon :color="video.link ? 'blue' : 'grey'">
                            mdi-link
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ video.link ? "Update" : "Add" }} Link</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="float-right"
                          @click.stop="deleteVideo(video)"
                        >
                          <v-icon color="red">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Video</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-center">
                <img 
                  v-if="video.type=='IMAGE'"
                  :src="videoPath(video)"
                  style="height: 180px; object-fit: cover;"
                />              
                <iframe
                  v-else
                  :src="video.type!='LINK' ? videoPath(video) : video.link"
                  frameborder="0"                  
                  style="max-height:400px"
                ></iframe>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="editVideosDialog = false" class="px-5">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showLinkDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h6">
          Link
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="imageLink"
            autofocus
            :error="linkErrors.link"
            :error-messages="linkErrors.link"
            placeholder="for e.g. www.xyz.com"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="closeAddLinkDialog()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="blue"
            dark
            class="px-5"
            :loading="isSavingLink"
            @click="updateObjectLink()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add videos dialog -->
    <v-dialog v-model="addVideoDialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="text-h5 mb-3">
          Add Files
        </v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-10">
              <v-file-input
                accept="image/png, image/gif, image/jpeg, image/jpg, video/webm, video/mp4, application/pdf"
                prepend-icon="mdi-file"
                label="Select Files"
                multiple
                chips
                outlined
                v-if="is_upload_video"
                v-model="selected_videos"
                :error="video_errors.object_file != null"
                :error-messages="video_errors.object_file"
              ></v-file-input>
              <v-text-field label="YouTube share link" prepend-icon="mdi-link" outlined v-else v-model="video_link"></v-text-field>
            </div>
            <div class="col-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="is_upload_video = !is_upload_video" v-bind="attrs" v-on="on" left class="mt-3">
                    <v-icon v-if="!is_upload_video">mdi-file-upload-outline</v-icon>
                    <v-icon v-else>mdi-link</v-icon>
                  </v-btn>
                </template>
                <span>Switch to {{ (!is_upload_video) ? 'File upload' : 'Link' }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"            
            @click="addVideoDialog = false"
            :disabled="addVideoLoader"
          >
            Close
          </v-btn>

          <v-btn
            color="primary"            
            @click="addVideos"
            :loading="addVideoLoader"
            :disabled="(is_upload_video && !selected_videos.length) || (!is_upload_video && !video_link)"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-speed-dial bottom fixed class="mb-3 mr-4 bg-white" left>
      <template v-slot:activator>
        <v-row dark>
          <v-btn color="primary" to="/booth/preview" tile>
            <v-icon left>
              mdi-eye
            </v-icon>
            Show Preview
          </v-btn>
        </v-row>
      </template>
    </v-speed-dial>
  </div>
</template>
<script>
import AddObject from "@/components/booth/AddObject";
import { mapState, mapMutations, mapActions } from "vuex";
import { unescape } from 'underscore';
import './booth_custom.css'

export default {
  name: "GoldBooth",
  components: {
    AddObject,
  },
  data() {
    return {
      model: 0,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      desc_y: 0,
      xb: 1260,
      last_xb: 1260,
      details: {
        booth_color: "grey",
        facebook: {},
      },
      desc_size: 180,
      descripton_overflow: false,
      companyDescription : '',
      shortCompanyDescription : '',
      show_preview: false,
      show_desc_modal: false,
      carouselHeight: null,
      editVideosDialog: false,
      selected_videos: [],
      addVideoDialog: false,
      addVideoLoader: false,
      video_errors: {},
      video_link: "",
      is_upload_video: true,
      showLinkDialog: false,
      imageLink: "",
      linkErrors: {},
      isSavingLink: false,
      selectedVideo: null
    };
  },
  computed: {
    ...mapState("vendor_booth", ["vendor_details"]),
    "showarrows" : function() {
      let length = this.vendor_details.object_data?.G1?.multiple_objects?.length;
      if(length && length > 1) {
        return true;
      } else {
        return false;
      }
    },
    asset_url() {
      return process.env.VUE_APP_ASSETS + "booth/gold/";
    },
  },
  watch: {
    "details.social_links.facebook": function(value) {
      if (value) {
        this.details.social_links.facebook_x = this.last_xb;
      }
    },
    "details.social_links.linkedin": function(value) {
      if (value) {
        this.details.social_links.linkedin_x = this.last_xb = this.last_xb + 50;
      }
    },
    "details.social_links.twitter": function(value) {
      if (value) {
        this.details.social_links.twitter_x = this.last_xb = this.last_xb + 50;
      }
    },
    "details.social_links.instagram": function(value) {
      if (value) {
        this.details.social_links.instagram_x = this.last_xb =
          this.last_xb + 50;
      }
    },
    "details.social_links.youtube": function(value) {
      if (value) {
        this.details.social_links.youtube_x = this.last_xb = this.last_xb + 50;
      }
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    ...mapMutations("add_object", ["setObjectData"]),
    ...mapActions("vendor_booth", ["getBoothsDetails"]),
    addObjectView(object) {
      console.log("object", object);
      let _self = this;
      _self.setObjectData({
        key: "selected_object",
        data: object,
      });
      _self.setObjectData({
        key: "show_add_object_modal",
        data: true,
      });
      _self.loading = false;
    },
    getAvatarPath(imageName) {
      if (typeof imageName === 'string' && imageName.endsWith('.png')) {
        return imageName.replace(/\.png$/, ".gif");
      }
      return imageName
    },
    async getBoothDetail() {
      let _self = this;
      let vendor_data;
      try {
        vendor_data = JSON.parse(localStorage.getItem("vendor_data"));
      } catch (error) {
        console.log(error);
      }
      await this.$axios
        .get(`vendor-booth/details/${vendor_data.id}`)
        .then((response) => {
          _self.details = response.data.data;
          if(_self.details.company_desc){
            _self.companyDescription = _self.htmlDecode(_self.details.company_desc);
            _self.shortCompanyDescription = _self.companyDescription
          }
        });
    },
    htmlDecode(input){
      try {
        input = unescape(input);

        const parser = new DOMParser();

        const document = parser.parseFromString(input, "text/html");

        return document.firstChild.innerHTML;
      } catch (error) {
        return ""
      }
    },
    getContactDetails(details) {
      if (details) {
        let contact_arr = details.split("\n");
        let x = 10;
        let y = 18;
        let svgTextHtml = ``;
        for (let i = 0; i <= contact_arr.length - 1; i++) {
          svgTextHtml += `<tspan x="${x}" y="${y + (i !=0 ? 5 : 0)}">${contact_arr[i]}</tspan>`;
          y = y + 18 + (i !=0 ? 5 : 0);
        }
        return svgTextHtml;
      }
    },
    getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect();
      var body = document.getElementsByTagName("svg")[0];
      var docEl = body;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop - 48;
      var left = box.left + scrollLeft - clientLeft;
      var width = box.width;
      var height = box.height;

      // console.log("elem",elem);
      // console.log("top",top);
      // console.log("left",left);

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height),
      };
    },
    setWidthOfCustomHtml() {
      let desc_image = this.$refs["desc_image"];
      let custom_html = this.$refs["custom_html"];
      let custom_sub_html = this.$refs["custom_sub_html"];
      let bounds = this.getCoords(desc_image);
      if(custom_html) {
        custom_html.style.position = "absolute";
        custom_html.style.left = bounds.left + "px";
        custom_html.style.top = bounds.top + "px";
        custom_html.style.width = bounds.width + "px";
        custom_html.style.height = bounds.height + "px";
      }
      if (custom_sub_html) {
        custom_sub_html.style.height = bounds.height + "px";
      }
      let carousel_prev_btn = this.$refs["carousel-prev-btn"];
      if(carousel_prev_btn){
        carousel_prev_btn.style.position = "absolute";
        carousel_prev_btn.style.left = "calc(58%)";
        carousel_prev_btn.style.top = 'calc(61.3%)';
      }
      let carousel_next_btn = this.$refs["carousel-next-btn"];
      if(carousel_next_btn){
        carousel_next_btn.style.position = "absolute";
        carousel_next_btn.style.left = "calc(62%)";
        carousel_next_btn.style.top = 'calc(61.3%)';
      }
      this.desc_size = bounds.height;
    },
    checkOverflow() {
      let el = this.$refs.custom_sub_html;
      if(el) {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible")
          el.style.overflow = "hidden";
        var isOverflowing =
          el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        if (isOverflowing) {
          this.descripton_overflow = true;
        }
      }
    },
    setWidthOfFrame() {
      // this.carouselHeight = 200;
      let video_iframe = this.$refs["video_iframe"];
      let video_iframe_span = this.$refs["video_iframe_span"];
      let bounds = this.getCoords(video_iframe);      
      video_iframe_span.style.position = "absolute";
      video_iframe_span.style.left = bounds.left + "px";
      video_iframe_span.style.top = bounds.top + "px";
      video_iframe_span.style.width = bounds.width + "px";
      video_iframe_span.style.height = bounds.height + "px";

      // let vcarousel = this.$refs["vcarousel"];
      // vcarousel.height = bounds.height + "px";
    },
    videoExists() {
      return this.vendor_details?.object_data?.G1?.multiple_objects?.length > 0
        ? true
        : false;
    },
    videoPath(video) {
      return video.path
        ? `${process.env.VUE_APP_RESOURCE_BASE_PATH}uploads/objects/${video.path}`
        : null;
    },
    async deleteVideo(video) {
      let _self = this
      await this.$axios
        .delete(`vendor/object/delete/${video.id}`)
        .then((response) => {
          let response_data = response.data;

          if (response_data.status) {
            _self.getBoothsDetails(true);
          }
        });
    },
    addVideos() {
      let _self = this;
      this.addVideoLoader = true;      
      let formData = new FormData();

      if(this.video_link) {
        if(this.video_link.search("https://www.youtube.com/embed/") == -1) {
          let index = this.video_link.lastIndexOf("/");
          if(index) {
            this.video_link = "https://www.youtube.com/embed/"+this.video_link.substring(index+1);
          }
        } 

        if(this.video_link.search("autoplay") == -1) {
          this.video_link = this.video_link+'?autoplay=1&mute=1'
        }
      }

      formData.append('vendor_id', this.vendor_details.id);
      formData.append('position_id', this.vendor_details.object_data.G1._id);
      formData.append('selected_object', "VIDEO");
      formData.append("is_contact_details",false);
      formData.append("is_social",false);
      formData.append("link", this.video_link);
      formData.append("is_upload_video", this.is_upload_video);

      for(let i=0; i<this.selected_videos.length ; i++) {
        formData.append("object_file[]", this.selected_videos[i]);
      }
      
      this.$axios
        .put(`vendor/object/add`,formData)
        .then((response) => {
          let response_data = response.data;
          _self.video_errors = {};

          if(!response_data.status) {
            if(response_data.error && response_data.error.length > 0) {
              for(let i=0; i<response_data.error.length ; i++) {
                _self.video_errors[response_data.error[i].field] = response_data.error[i].message;
              }
            }
          } else {
            _self.selected_videos = [];
            _self.addVideoDialog = false;
            _self.video_link = "";

            if (response_data.status) {
              _self.getBoothsDetails(true);
            }
          }
          _self.addVideoLoader = false;
        })
        .catch((e) => {
          console.log(e);
          _self.addVideoLoader = false;
        });
    },
    getUnescapedString(string) {
        var e = document.createElement('div');
        string = string ? string : "";
        e.innerHTML = string;
        let unescapedString = e?.childNodes[0]?.nodeValue;
        return unescapedString ? e.childNodes[0].nodeValue : "";
    },
    openLink(route) {
      this.$router.push(route);
    },
    openExternalLink(data, isObject = true) {
      let link;
      if(isObject) {
        link = data.link;
      } else {
        link = data;
      }

      if (link) {
        if (!link.startsWith("http")) {
          link = "https://" + link
        }
        window.open(link)
      }
    },
    showAddLinkDialog(videoData) {
      this.selectedVideo = videoData;
      this.imageLink = videoData.link || "";
      this.showLinkDialog = true;
    },
    closeAddLinkDialog() {
      this.showLinkDialog = false;
      this.imageLink = "";
      this.selectedVideo = null;
      this.linkErrors = {};
    },
    updateObjectLink() {
      if (!this.selectedVideo) {
        return null
      }
      
      let isValidationError = false;

      this.isSavingLink = true;

      this.$axios
        .post(`vendor/object/link`, {
          vendorObjectId: this.selectedVideo.id,
          link: this.imageLink || ""
        })
        .then((res) => {
          let resData = res.data;

          this.linkErrors = {};

          if(resData.status) {
            this.selectedVideo.link = this.imageLink || "";
            this.setAlert({
              show: true,
              variant: "success",
              message: resData.message,
              dismissCountDown: 3000,
            });
          } else {
            if (String(resData.type).toLowerCase() === "validation") {
              isValidationError = true;
              for(let i=0; i<resData.error.length ; i++) {
                let error = resData.error[i];
                this.linkErrors[error.field] = error.message;
              }
            } else {
              this.setAlert({
                show: true,
                variant: "error",
                message: resData.message || "Something went wrong",
                dismissCountDown: 3000,
              });
            }
          }
        })
        .catch(() => {
          this.setAlert({
            show: true,
            variant: "error",
            message: "Something went wrong",
            dismissCountDown: 3000,
          });
        })
        .finally(() => {
          this.isSavingLink = false;
          if (!isValidationError) {
            this.closeAddLinkDialog();
          }
        })
    }
  },
  mounted() {
    this.getBoothDetail().then(() => {
      this.setWidthOfCustomHtml();
      this.setWidthOfFrame();
      this.checkOverflow();
    });
    window.onresize = () => {
      this.setWidthOfCustomHtml();
      this.setWidthOfFrame();
      this.checkOverflow();
    };    
    
  },
};
</script>
<style>
.st0 {
  fill: rgba(0, 0, 0, 0.25) !important;
}
.cursor-pointer {
  cursor: url('/edit-20.png'), auto;
}
/* .v-btn--is-elevated{
  position: relative;
  bottom: 45px;
} */
.dynamic-slider .v-carousel, .dynamic-slider .v-carousel__item {
  height: 100% !important;
}
</style>

<style lang="scss">
.v_g_description_container > p {
  margin-bottom: 0px;
  color: white;
}

.v_g_description_container > p > :not(a) {
  color: white !important;
}

.v_g_description_container a {
  color: #0026b5;
}

.add_video_content_container .v-expansion-panel-content__wrap{
  padding: 16px 16px 10px 16px;
}
</style>