<template>
  <v-app id="inspire" class="grey lighten-4">
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="primary"
      dark
      dense
      class="elevation-5"
      flat
      fixed
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer; scrollTop()" ></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 530px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">VBC Vendor's Portal ( {{ vendor_details.name }} )</span>
      </v-toolbar-title>
      <v-spacer class="spacer-2"></v-spacer>
      <v-spacer></v-spacer>
      <v-row class="mr-3" align="center" v-if="$route.meta.boothview && vendor_details.sponsor_type!=3">
        <v-subheader> Select Avatar </v-subheader>
        <v-select
          v-model="selected_avatar"
          :items="avatars"
          item-text="name"
          item-value="value"
          label="Booth Avatar"
          solo
          dense
          hide-details
          @change="updateBoothAvatar"
          style="max-width: 230px"
        ></v-select>
      </v-row>
      <v-row  class="mr-3" align="center" v-if="$route.meta.boothview && vendor_details.sponsor_type!=3">
        <v-subheader> Set Booth Color </v-subheader>
        <v-select
          v-model="selected_booth_color"
          :items="booth_colors"
          item-text="name"
          item-value="color"
          label="Booth Color"
          solo
          dense
          hide-details
          @change="updateBoothImage"
          style="max-width: 230px"
        ></v-select>
      </v-row>
      <v-menu
        :close-on-content-click="closeOnClick"
        :nudge-width="200"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon large v-bind="attrs" v-on="on">
            <v-avatar color="black" size="36">
              <span class="white--text headline">{{
                user_data.name.substring(0, 1)
              }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="black" size="36">
                  <span class="white--text headline">{{
                    user_data.name.substring(0, 1)
                  }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-if="user_data">{{
                  user_data.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item to="/profile">
              <v-icon>mdi-account-cog</v-icon>
              <v-list-item-title class="ml-2">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item to="/change-password">
              <v-icon>mdi-lock-outline</v-icon>
              <v-list-item-title class="ml-2"
                >Change password</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="logout()">
              <v-icon>mdi-logout</v-icon>
              <v-list-item-title class="ml-2">Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary color="white">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar color="primary" size="36">
              <span class="white--text headline">{{user_data.name.substring(0, 1)}}</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="user_data"> {{user_data.name}} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense>
        <template>
          <span v-for="item in items" :key="item.text">
            <v-list-item
              :key="item.text"
              :to="item.link"
              link
              color="secondary"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </span>
          <v-list-item key="logout" @click="logout()" color="secondary">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <router-view />
    </v-main>
    <Alert />
  </v-app>
</template>
<script>
import Alert from "@/components/Alert.vue";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    source: String,
  },
  name: "App",
  components: {
    Alert,
  },
  data() {
    return {
      selected_booth_color: "grey",
      selected_avatar: 'avatar_male_black.gif',
      dialog: false,
      drawer: null,
      loading: false,
      user_data: {
        name: " ",
      },
      closeOnClick: true,
      items: [
        {
          icon: "mdi-home-assistant",
          text: "Booth Management",
          link: "/booth",
        },
        // {
        //   icon: "mdi-account-group-outline",
        //   text: "Representatives",
        //   link: "/repesentative",
        // },
        {
          icon: "mdi-file-document-multiple-outline",
          text: "Resources List",
          link: "/resource/list",
        },
        {
          icon: "mdi-account-cog",
          text: "My Profile",
          link: "/profile",
        },
        {
          icon: "mdi-lock-outline",
          text: "Change Password",
          link: "/change-password",
        },
      ],
      booth_colors: [
        { name: "Slate", color: "slate" },
        { name: "Gray", color: "gray" },
        { name: "Red", color: "red" },
        { name: "Red-Orange", color: "red_orange" },
        { name: "Orange", color: "orange" },
        { name: "Yellow", color: "yellow" },
        { name: "Yellow-Orange", color: "yellow_orange" },
        { name: "Yellow-Green", color: "yellow_green" },
        { name: "Green", color: "green" },
        { name: "Blue-Green", color: "blue_green" },
        { name: "Blue", color: "blue" },
        { name: "Blue-Violet", color: "blue_violet" },
        { name: "Violet", color: "violet" },
        { name: "Red-Violet", color: "red_violet" },
      ],
      avatars: [
        { name: "Avatar One", value: "avatar_male_black.gif" },        
        { name: "Avatar Two",value: "avatar_female_black.gif"},
        { name: "Avatar Three",value: "avatar_male_asian.gif" },
        { name: "Avatar Four",value: "avatar_female_asian.gif" },
        { name: "Avatar Five",value: "avatar_male_latino.gif" },
        { name: "Avatar Six",value: "avatar_female_latina.gif" },
        { name: "Avatar Seven",value: "avatar_male_white.gif" },
        { name: "Avatar Eight",value: "avatar_female_white.gif" }
      ]
    };
  },
  computed: {
    ...mapState("vendor_booth", ["vendor_details"]),
  },
  methods: {
    ...mapMutations("vendor_booth", ["setBoothData", "setSocialIcons"]),
    ...mapActions("vendor_booth", ["getBoothsDetails"]),
    updateBoothAvatar() {
      this.loading = true
      this.$axios
        .put(`vendor/update_booth_avatar/${this.user_data.id}`, { booth_avatar: this.selected_avatar })
        .then((response) => {
          if (response.data.status) {
            this.getBoothsDetails(true);
          }
        })
        .catch(() => {          
        }).then(() =>{
          this.loading = false;
        });
    },
    updateBoothImage() {
      let _self = this;
      this.loading = true
      this.$axios
        .put(`vendor/update_booth_color/${this.user_data.id}`, { booth_color: _self.selected_booth_color })
        .then((response) => {
          if (response.data.status) {
            _self.getBoothsDetails(true);
          }
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        }).then(() =>{
          this.loading = false;
        });
    },
    scrollTop(){
      window.scrollTo({
      top: 0,
      left: 0,
    });
    },
    logout() {
      let _self = this
      this.$axios.post(`/auth/vendor/logout`,{
        refreshToken : _self.user_data.tokens.refresh.token
      })
      .then(() => {
        localStorage.removeItem("vendor_data");
        this.$router.push("/login");
      })
      .catch((e) => {
        console.log(e);
        _self.loading = false;
      });
      
    },
  },
  mounted: function () {
    this.user_data = JSON.parse(localStorage.getItem("vendor_data"));
  },
  created: function () {
    this.getBoothsDetails(true).then(() => {
      this.selected_avatar = this.vendor_details.booth_avatar;
      this.selected_booth_color = this.vendor_details.booth_color;
    });
  },
};
</script>
<style>
.link-opacity {
  opacity: 0;
}
</style>