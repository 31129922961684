<template>
  <div style="height: 100%">
    <v-speed-dial bottom fixed class="mb-3 mr-4 bg-white" left>
      <template v-slot:activator>
        <v-row dark>
          <v-btn color="primary" to="/booth" tile>
            <v-icon left>
              mdi-arrow-left
            </v-icon>
            Back
          </v-btn>
        </v-row>
      </template>
    </v-speed-dial>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 1920 1440"
    >
      <defs>
        <filter x="-0.1" y="-0.1" width="1.2" height="1.2" id="contact_details_bg">
          <feFlood flood-color="rgba(100,100,100,0.2)" result="bg" />
          <feMerge>
            <feMergeNode in="bg"/>
            <feMergeNode in="SourceGraphic"/>
            <feComposite in2="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <defs>
        <clipPath id="clip-Canvas">
          <rect width="1920" height="1440" />
        </clipPath>
      </defs>
      <g id="Canvas" clip-path="url(#clip-Canvas)">
        <rect width="1920" height="1440" fill="rgba(0,0,0,0)" />
        <g id="Color_Selections" data-name="Color Selections">
          <image
            id="booth_gold_red"
            width="1920"
            height="1440"
            v-if="details.booth_color"
            :xlink:href="asset_url + details.booth_color + '.png'"
          />
        </g>
        <g id="Avatars">
          <image
            id="avatar_male_black"
            width="160"
            height="208"
            transform="translate(775 668)"
            :xlink:href="asset_url + getAvatarPath(details.booth_avatar)"
          />
          <!-- avatar_male_black,avatar_female_black,avatar_male_asian,avatar_female_asian,avatar_male_latino,avatar_female_latina,avatar_male_white,avatar_female_white -->
        </g>
        <g id="Mapping">
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="40"
            height="40"
            v-if="details.social_links && details.social_links.facebook"
            fill="rgba(254,219,190,0)"
            :transform="
              'translate(' + details.social_links.facebook_x + ' 516)'
            "
          >
            <title>Facebook {{ details }}</title>
          </rect>
          <image
            id="Layer_2"
            data-name="Layer 2"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.facebook_x + ' 516)'
            "
            v-if="details.social_links && details.social_links.facebook"
            :xlink:href="asset_url + '../../facebook_icon.png'"
            :class="{'cursor-pointer' : externalLinkExist(details.social_links.facebook) }"
            @click="openExternalLink(details.social_links.facebook, false)"
          >
            <title>Facebook</title>
          </image>
          <rect
            id="Rectangle_8_copy"
            data-name="Rectangle 8 copy"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.linkedin_x + ' 516)'
            "
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.linkedin"
          >
            <title>Linkedin</title>
          </rect>
          <image
            id="Layer_3"
            data-name="Layer 3"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.linkedin_x + ' 516)'
            "
            :xlink:href="asset_url + '../../linkedin_icon.png'"
            v-if="details.social_links && details.social_links.linkedin"
            :class="{'cursor-pointer' : externalLinkExist(details.social_links.linkedin) }"
            @click="openExternalLink(details.social_links.linkedin, false)"
          >
            <title>Linkedin</title>
          </image>
          <rect
            id="Rectangle_8_copy_2"
            data-name="Rectangle 8 copy 2"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.twitter_x + ' 516)'"
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.twitter"
          >
            <title>Twitter</title>
          </rect>
          <image
            id="Layer_4"
            data-name="Layer 4"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.twitter_x + ' 516)'"
            :xlink:href="asset_url + '../../twitter_icon.png'"
            v-if="details.social_links && details.social_links.twitter"
            :class="{'cursor-pointer' : externalLinkExist(details.social_links.twitter) }"
            @click="openExternalLink(details.social_links.twitter, false)"
          >
            <title>Twitter</title>
          </image>
          <rect
            id="Rectangle_8_copy_3"
            data-name="Rectangle 8 copy 3"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.instagram_x + ' 516)'
            "
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.instagram"
          >
            <title>Instagram</title>
          </rect>
          <image
            id="Layer_5"
            data-name="Layer 5"
            width="40"
            height="40"
            :transform="
              'translate(' + details.social_links.instagram_x + ' 516)'
            "
            :xlink:href="asset_url + '../../instagram_icon.png'"
            v-if="details.social_links && details.social_links.instagram"
            :class="{'cursor-pointer' : externalLinkExist(details.social_links.instagram) }"
            @click="openExternalLink(details.social_links.instagram, false)"
          >
            <title>Instagram</title>
          </image>
          <rect
            id="Rectangle_8_copy_4"
            data-name="Rectangle 8 copy 4"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.youtube_x + ' 516)'"
            fill="rgba(254,219,190,0)"
            v-if="details.social_links && details.social_links.youtube"
          >
            <title>Youtube</title>
          </rect>
          <image
            id="Layer_6"
            data-name="Layer 6"
            width="40"
            height="40"
            :transform="'translate(' + details.social_links.youtube_x + ' 516)'"
            :xlink:href="asset_url + '../../youtube_icon.png'"
            v-if="details.social_links && details.social_links.youtube"
            :class="{'cursor-pointer' : externalLinkExist(details.social_links.youtube) }"
            @click="openExternalLink(details.social_links.youtube, false)"
          >
            <title>Youtube</title>
          </image>
          <rect
            id="Rectangle_7"
            data-name="Rectangle 7"
            width="192"
            height="60"
            transform="translate(978 916)"
            fill="rgba(60, 120, 180, 0)"
            class="cursor-pointer"
            v-if="!(details.object_data && details.object_data.G4 && details.object_data.G4.contact_details)"
          >
            <title>contact details</title>
          </rect>
          <text
            id="Layer_7"
            filter="url(#contact_details_bg)"
            style="font-weight: 500"
            data-name="Layer 7"
            width="192"
            height="60"
            transform="translate(978 940)"
            fill="black"
            v-if="details.object_data && details.object_data.G4 && details.object_data.G4.contact_details"
            v-html="getContactDetails(details.object_data.G4.contact_details)"
            class="cursor-pointer"
            title="contact details"
          >
          </text>
          <rect
            id="Rectangle_9"
            data-name="Rectangle 9"
            width="200"
            height="370"
            transform="translate(1555 833)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>banner</title>
          </rect>
          <image
            id="Layer_8"
            data-name="Layer 8"
            width="200"
            height="370"
            transform="translate(1555 833)"
            v-if="details && details.object_data"
            :xlink:href="details.object_data.G5.upload_url"
            class="cursor-pointer"
            :class="{'cursor-pointer' : externalLinkExist(details.object_data.G5) }"
            @click="openExternalLink(details.object_data.G5)"
          >
            <title>banner</title>
          </image>
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="180"
            height="180"
            transform="translate(357 676)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>Giveaway Image</title>
          </rect>
          <image
            id="Layer_9"
            data-name="Layer 9"
            width="180"
            height="180"
            transform="translate(357 676)"
            :content="details.object_data.G3.upload_url"
            v-if="details && details.object_data"
            :xlink:href="details.object_data.G3.upload_url"
            :class="{'cursor-pointer' : externalLinkExist(details.object_data.G3) }"
            @click="openExternalLink(details.object_data.G3)"
          >
            <title>Giveaway Image</title>
          </image>
          <rect
            id="Rectangle_6"
            ref="video_iframe"
            data-name="Rectangle 6"
            width="426"
            height="240"
            transform="translate(967 646)"
            fill="rgba(254,219,190,0)"
          />
          <!-- <image id="Layer_10" data-name="Layer 10" width="426" height="240" transform="translate(967 646)" v-if="details && details.object_data" :xlink:href="details.object_data.G1.upload_url"/> -->
          <rect
            id="Rectangle_3"
            ref="desc_image"
            class="st0 cursor-pointer"
            data-name="Rectangle 3"
            width="200"
            height="240"
            transform="translate(557 646)"
            fill="rgba(254,219,190,0)"
          >
            <title>description</title>
          </rect>
          <image
            id="Layer_11"
            class="link-opacity cursor-pointer"
            data-name="Layer 11"
            width="200"
            height="240"
            transform="translate(557 646)"
            :xlink:href="asset_url + 'VBCEH_Booth_Gold_4x3-image32.png'"
          >
            <title>description</title>
          </image>
          <rect
            id="Rectangle_5"
            data-name="Rectangle 5"
            width="80"
            height="80"
            transform="translate(815 961)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>booth icon</title>
          </rect>
          <image
            id="Layer_12"
            data-name="Layer 12"
            width="80"
            height="80"
            transform="translate(815 961)"
            v-if="details && details.object_data"
            :xlink:href="details.object_data.G6.upload_url"
            :class="{'cursor-pointer' : externalLinkExist(details.object_data.G6) }"
            @click="openExternalLink(details.object_data.G6)"
          >
            <title>booth icon</title>
          </image>
          <rect
            id="Rectangle_4"
            data-name="Rectangle 4"
            width="160"
            height="36"
            transform="translate(775 839)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          />
          <image
            id="Layer_13"
            class="link-opacity cursor-pointer"
            data-name="Layer 13"
            width="160"
            height="36"
            transform="translate(775 839)"
            :xlink:href="asset_url + 'VBCEH_Booth_Gold_4x3-image34.png'"
            @click="openResourcePopup"
          />
          <rect
            id="Rectangle_1"
            data-name="Rectangle 1"
            width="442"
            height="176"
            transform="translate(614 361)"
            fill="rgba(254,219,190,0)"
            class="cursor-pointer"
          >
            <title>logo</title>
          </rect>
          <image
            id="Layer_14"
            data-name="Layer 14"
            width="442"
            height="176"
            transform="translate(614 361)"
            v-if="details && details.object_data"
            :xlink:href="details.object_data.G2.upload_url"
            :class="{'cursor-pointer' : externalLinkExist(details.object_data.G2) }"
            @click="openExternalLink(details.object_data.G2)"
          >
            <title>logo</title>
          </image>
        </g>
      </g>
    </svg>
    <span
      ref="custom_html"
      style="color: white; font-size: 0.9vw;word-break: break-word;"
      class="pl-1"
      v-if="details.company_desc"
    >
      <span class="ellipsis v_g_p_description_container custom_sub_html" :class="[details.booth_color+'_custom_color']" ref="custom_sub_html" v-html="shortCompanyDescription"></span>
      <span
        v-if="details.company_desc.length > desc_size"
        class="yellow--text cursor_pointer"
        @click="show_desc_modal = true"
        v-show="descripton_overflow"
      >
        Read More
      </span>
    </span>
    <span ref="video_iframe_span" class="pl-1" v-if="details">
      <v-carousel
        v-if="details.object_data"
        v-model="model"
        :show-arrows="false"
        hide-delimiters
        ref="vcarousel"
      >
        <v-carousel-item
          v-for="(data, i) in details.object_data.G1.multiple_objects"
          :key="data.id"
          ref="vcarouselitem"
        >
          <v-row class="fill-height ma-0" v-if="model == i">
            <video :id="i" width="100%" ref="iframe" v-if="details && details.object_data && data.type=='VIDEO'" :src="'https://vbcassets.s3.us-east-2.amazonaws.com/uploads/objects/'+data.path" controls autoplay></video>
            <img :id="i" v-else-if="details && details.object_data && data.type=='IMAGE'" width="100%" :src="'https://vbcassets.s3.us-east-2.amazonaws.com/uploads/objects/'+data.path" :style="{ objectFit: 'contain !important', height: '100%', cursor: data.link ? 'pointer' : 'normal' }" @click="data.link ? openExternalLink(data) : null"/>
            <iframe :id="i" width="100%" ref="iframe" v-else-if="details && details.object_data && data.type=='LINK'" :src="data.link" muted sandbox="allow-scripts allow-presentation allow-same-origin" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write" allowfullscreen webkitallowfullscreen mozallowfullscreen frameborder="0"></iframe>
            <iframe :id="i" width="100%" ref="iframe" v-else-if="details && details.object_data" :src="'https://vbcassets.s3.us-east-2.amazonaws.com/uploads/objects/'+data.path" muted sandbox="allow-scripts allow-presentation allow-same-origin" allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write" autoplay="1" allowfullscreen webkitallowfullscreen mozallowfullscreen frameborder="0"></iframe>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </span>
    <span ref="carousel-prev-btn">
      <v-btn
        v-if="details && details.object_data && details.object_data.G1.multiple_objects.length>1"
        x-small
        color="warning"
        dark
        elevation="0"
        :disabled="model == 0"
        @click="model--"
      >
        <v-icon size="18">
            mdi-chevron-left
        </v-icon>
      </v-btn>
    </span>
    <span ref="carousel-next-btn">
      <v-btn
        v-if="details && details.object_data && details.object_data.G1.multiple_objects.length>1"
        x-small
        color="warning"
        dark
        elevation="0"
        :disabled="model == details.object_data.G1.multiple_objects.length-1"
        @click="model++"
      >
        <v-icon size="18">
            mdi-chevron-right
        </v-icon>
      </v-btn>
    </span>
    <v-dialog v-model="show_desc_modal" max-width="600px">
      <v-card>
        <v-card-title class="px-3 py-2">Company Description</v-card-title>
        <v-divider />
        <v-card-text class="pa-3 pb-0 rounded-lg c_g_p_description_container" v-html="companyDescription">
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn color="grey darken-4" text @click="show_desc_modal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <resource-list :vendor_id="_self.details.id" :display_dialog="display_resource_dialog" @close-resource-popup="closeResourcePopup" />
  </div>
</template>
<script>
import ResourceList from './ResourceList.vue';
import { unescape } from 'underscore';
import './booth_custom.css'

export default {
  components: { ResourceList },
  name: "GoldBooth",
  data() {
    return {
      model: 0,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      desc_y: 0,
      xb: 1260,
      last_xb: 1260,
      details: {
        booth_color: "grey",
        facebook: {},
      },
      desc_size: 180,
      descripton_overflow: false,
      companyDescription : '',
      shortCompanyDescription : '',
      show_preview: false,
      show_desc_modal: false,
      carouselHeight: null,
      display_resource_dialog: false,
    };
  },
  computed: {
    "showarrows" : function() {
      let length = this.details.object_data?.G1?.multiple_objects?.length;

      if(length && length > 1) {
        return true;
      } else {
        return false;
      }
    },
    asset_url() {
      return process.env.VUE_APP_ASSETS + "booth/gold/";
    },
  },
  watch: {
    "details.social_links.facebook": function(value) {
      if (value) {
        this.details.social_links.facebook_x = this.last_xb;
      }
    },
    "details.social_links.linkedin": function(value) {
      if (value) {
        this.details.social_links.linkedin_x = this.last_xb = this.last_xb + 50;
      }
    },
    "details.social_links.twitter": function(value) {
      if (value) {
        this.details.social_links.twitter_x = this.last_xb = this.last_xb + 50;
      }
    },
    "details.social_links.instagram": function(value) {
      if (value) {
        this.details.social_links.instagram_x = this.last_xb =
          this.last_xb + 50;
      }
    },
    "details.social_links.youtube": function(value) {
      if (value) {
        this.details.social_links.youtube_x = this.last_xb = this.last_xb + 50;
      }
    },
  },
  methods: {
    async getBoothDetail() {
      let _self = this;
      let vendor_data;
      try {
        vendor_data = JSON.parse(localStorage.getItem("vendor_data"));
      } catch (error) {
        console.log(error);
      }
      await this.$axios
        .get(`vendor-booth/details/${vendor_data.id}`, {
            params: {
                preview: true
            }
        })
        .then((response) => {
          _self.details = response.data.data;
          if(_self.details.company_desc){
            _self.companyDescription = _self.htmlDecode(_self.details.company_desc);
            _self.shortCompanyDescription = _self.companyDescription
          }
        });
    },
    htmlDecode(input){
      try {
        input = unescape(input);

        const parser = new DOMParser();

        const document = parser.parseFromString(input, "text/html");

        return document.firstChild.innerHTML;
      } catch (error) {
        return ""
      }
    },
    getContactDetails(details) {
      if (details) {
        let contact_arr = details.split("\n");
        let x = 10;
        let y = 18;
        let svgTextHtml = ``;
        for (let i = 0; i <= contact_arr.length - 1; i++) {
          svgTextHtml += `<tspan x="${x}" y="${y + (i !=0 ? 5 : 0)}">${contact_arr[i]}</tspan>`;
          y = y + 18 + (i !=0 ? 5 : 0);
        }
        return svgTextHtml;
      }
    },
    getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect();
      var body = document.getElementsByTagName("svg")[0];
      var docEl = body;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft =
        window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top = box.top + scrollTop - clientTop - 48;
      var left = box.left + scrollLeft - clientLeft;
      var width = box.width;
      var height = box.height;

      // console.log("elem",elem);
      // console.log("top",top);
      // console.log("left",left);

      return {
        top: Math.round(top),
        left: Math.round(left),
        width: Math.round(width),
        height: Math.round(height),
      };
    },
    setWidthOfCustomHtml() {
      let desc_image = this.$refs["desc_image"];
      let custom_html = this.$refs["custom_html"];
      let custom_sub_html = this.$refs["custom_sub_html"];
      let bounds = this.getCoords(desc_image);
      if(custom_html) {
        custom_html.style.position = "absolute";
        custom_html.style.left = bounds.left + "px";
        custom_html.style.top = bounds.top + "px";
        custom_html.style.width = bounds.width + "px";
        custom_html.style.height = bounds.height + "px";
      }
      if (custom_sub_html) {
        custom_sub_html.style.height = bounds.height + "px";
      }
      let carousel_prev_btn = this.$refs["carousel-prev-btn"];
      if(carousel_prev_btn){
        carousel_prev_btn.style.position = "absolute";
        carousel_prev_btn.style.left = "calc(58%)";
        carousel_prev_btn.style.top = 'calc(61.3%)';
      }
      let carousel_next_btn = this.$refs["carousel-next-btn"];
      if(carousel_next_btn){
        carousel_next_btn.style.position = "absolute";
        carousel_next_btn.style.left = "calc(62%)";
        carousel_next_btn.style.top = 'calc(61.3%)';
      }
      this.desc_size = bounds.height;
    },
    checkOverflow() {
      let el = this.$refs.custom_sub_html;
      if(el) {
        var curOverflow = el.style.overflow;
        if (!curOverflow || curOverflow === "visible")
          el.style.overflow = "hidden";
        var isOverflowing =
          el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
        if (isOverflowing) {
          this.descripton_overflow = true;
        }
      }
    },
    setWidthOfFrame() {
      // this.carouselHeight = 200;
      let video_iframe = this.$refs["video_iframe"];
      let video_iframe_span = this.$refs["video_iframe_span"];
      let bounds = this.getCoords(video_iframe);
      video_iframe_span.style.position = "absolute";
      video_iframe_span.style.left = bounds.left + "px";
      video_iframe_span.style.top = bounds.top + "px";
      video_iframe_span.style.width = bounds.width + "px";
      video_iframe_span.style.height = bounds.height + "px";

      let vcarousel = this.$refs["vcarousel"];
      vcarousel.height = bounds.height + "px";
    },
    videoExists() {
      return this.details?.object_data?.G1?.multiple_objects?.length > 0
        ? true
        : false;
    },
    videoPath(video) {
      return video.path
        ? `${process.env.VUE_APP_RESOURCE_BASE_PATH}uploads/objects/${video.path}`
        : null;
    },
    async deleteVideo(video) {
      let _self = this
      await this.$axios
        .delete(`vendor/object/delete/${video.id}`)
        .then((response) => {
          let response_data = response.data;

          if (response_data.status) {
            _self.getBoothsDetails(true);
          }
        });
    },
    addVideos() {
      let _self = this;
      this.addVideoLoader = true;      
      let formData = new FormData();

      formData.append('vendor_id', this.details.id);
      formData.append('position_id', this.details.object_data.G1._id);
      formData.append('selected_object', "VIDEO");
      formData.append("is_contact_details",false);
      formData.append("is_social",false);

      for(let i=0; i<this.selected_videos.length ; i++) {
        formData.append("object_file[]", this.selected_videos[i]);
      }
      
      this.$axios
        .put(`vendor/object/add`,formData)
        .then((response) => {
          let response_data = response.data;

          _self.selected_videos = [];
          _self.addVideoDialog = false;

          if (response_data.status) {
            _self.getBoothsDetails(true);
          }
          _self.addVideoLoader = false;
        })
        .catch((e) => {
          console.log(e);
          _self.addVideoLoader = false;
        });
    },
    getUnescapedString(string) {
        var e = document.createElement('div');
        string = string ? string : "";
        e.innerHTML = string;
        let unescapedString = e?.childNodes[0]?.nodeValue;
        return unescapedString ? e.childNodes[0].nodeValue : "";
    },
    openLink(route) {
      this.$router.push(route);
    },
    externalLinkExist(data, isObject = true) {
      let link;
      
      if(isObject) {
        link = data.link;
      } else {
        link = data;
      }

      return link ? true : false;
    },
    openExternalLink(data, isObject = true) {
      let link;
      if(isObject) {
        link = data.link;
      } else {
        link = data;
      }

      if (link) {
        if (!link.startsWith("http")) {
          link = "https://" + link
        }
        window.open(link)
      }
    },
    getAvatarPath(imageName) {
      if (typeof imageName === 'string' && imageName.endsWith('.png')) {
        return imageName.replace(/\.png$/, ".gif");
      }
      return imageName
    },
    openResourcePopup() {
      this.display_resource_dialog = true;
    },
    closeResourcePopup() {
      this.display_resource_dialog = false;
    }
  },
  mounted() {
    this.getBoothDetail().then(() => {
      this.setWidthOfCustomHtml();
      this.setWidthOfFrame();
      this.checkOverflow();
    });
    window.onresize = () => {
      this.setWidthOfCustomHtml();
      this.setWidthOfFrame();
      this.checkOverflow();
    };
  },
};
</script>
<style scoped>
.st0 {
  fill: rgba(0, 0, 0, 0.25) !important;
}

.cursor-pointer {
  cursor: pointer;
}
/* .v-btn--is-elevated{
  position: relative;
  bottom: 45px;
} */
</style>

<style lang="scss">
.v_g_p_description_container > p {
  margin-bottom: 0px;
  color: white;
}

.v_g_p_description_container > p > :not(a) {
  color: white !important;
}

.v_g_p_description_container a {
  color: #0026b5;
}

.c_g_p_description_container {
  a {
    color: #0026b5 !important;
  } 

  p {
    margin-bottom: 0px;
    color: rgba(0, 0, 0, 0.6) !important;
  }
}
</style>