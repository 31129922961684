const state = {
  show_add_object_modal : false,
  selected_postion : '',
  selected_postion_title : '',
  selected_object : {}
}
const actions = {
  closeObjectModal({commit}){
    let res_data = {
      key : 'show_add_object_modal',
      data : false
    }
    commit('setObjectData',res_data)
    let res_data1 = {
      key : 'selected_postion',
      data : ''
    }
    commit('setObjectData',res_data1)
  }
}
const getters = {}
const mutations = {
  setObjectData(state,data){
    state[data.key] = data.data
  },
}
// import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}