import Vue from 'vue'
import Vuex from 'vuex'
import utils from './utils'
import add_object from './add_object'
import vendor_booth from './vendor_booth'
import resource from './resource'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    utils,
    add_object,
    vendor_booth,
    resource
  }
})

export default store