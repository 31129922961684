<template>
  <v-app id="inspire">
      <router-view />
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },
  mounted: function(){
    var style = 'color: tomato; background:#eee; -webkit-text-stroke: 1px black; font-size:30px;';
    console.log('%cHi, We are Happy 😆 to have you as our vendor', style);    
  }
};
</script>