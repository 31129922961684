import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
let opts = {
  theme: {
    themes : {
      light: {
        primary: '#1a4476',
        secondary: '#80bc40',
        error : '#EF5350',
        warning : '#f2a62f'
    },
    dark : {
      secondary: '#1a4476',
      primary: '#80bc40',
      error : '#EF5350',
      warning : '#f2a62f'
    }
    }
  }
};
export default new Vuetify(opts);
