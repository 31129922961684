<template>
  <v-dialog
    v-model="display_dialog"
    max-width="600"
    @click:outside="closePopup"
    persistent
  >
    <v-card>
      <v-toolbar dark flat color="primary" dense>
        <v-toolbar-title>Resource</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card flat>
            <v-card-text class="px-0 py-0">
              <v-list>
                <div v-for="(item, index) in resource_list" :key="index">
                  <v-list-item>
                    <v-btn color="primary" class="mx-1" :href="item.file_type == 'LINK' ? item.file_link : item.file_info.location" small target="_blank">
                      View
                    </v-btn>
                    <v-list-item-content class="mx-4">
                      <v-list-item-title>{{item.title}}</v-list-item-title>
                      <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
                <div v-if="resource_list.length == 0" class="py-3 text-center">
                  No Resource Found
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name : 'Resource',
  props : ['vendor_id', 'display_dialog'],
  data(){
    return {
      resource_list : [],
    }
  },
  watch : {
    vendor_id : function (value) {
      if(value){
        this.getResource()
      }
    }
  },
  methods : {
    closePopup(){
      this.$emit('close-resource-popup',true);
    },
    getResource(){
      this.$axios.get('/resource/list/'+this.vendor_id,{
        params : {
          pagination : false
        }
      }).then((response)=>{
        this.resource_list = response.data.data
      })
    }
  },
  mounted() {
    this.getResource();
  }
};
</script>