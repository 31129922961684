import axios from 'axios';
let userdata = JSON.parse(localStorage.getItem('vendor_data'));
let token;
if(userdata && userdata.tokens && userdata.tokens.access.token){
  token = userdata.tokens.access.token
}
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common = {
  "Authorization": "Bearer "+ token,
  "Accept" : 'application/x-www-form-urlencoded'
};
function createAxiosResponseInterceptor() {
  const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
          if (error.response.status !== 401) {
              return Promise.reject(error);
          }
          axios.interceptors.response.eject(interceptor);
          return axios.post('/auth/vendor/refresh-tokens', {
            refreshToken : userdata.tokens.refresh.token
          }).then(response => {              
              error.response.config.headers['Authorization'] = 'Bearer ' + response.data.access.token;
              axios.defaults.headers.common = {
                "Authorization": "Bearer "+ response.data.access.token,
                "Accept" : 'application/x-www-form-urlencoded'
              };
              userdata.tokens = response.data
              localStorage.setItem("vendor_data",JSON.stringify(userdata)); 
              return axios(error.response.config);
          }).catch(error => {      
              localStorage.removeItem("vendor_data");         
              window.location.reload()
              return Promise.reject(error);
          }).finally(createAxiosResponseInterceptor());
      }
  );
}
axios.interceptors.response.use(createAxiosResponseInterceptor());
export default axios